import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";

import { useAuthStore } from "@/pstore/auth";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/sign-in",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/apps/authorization/views/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "Sign In",
        },
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
        meta: {
          pageTitle: "Sign Up",
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
        meta: {
          pageTitle: "Password reset",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/sign-in",
  },
];

const router = createRouter({
  history: createWebHistory(
    process.env.NODE_ENV === "production" ? "/" : "/authorization"
  ),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // console.log(`From: ${String(from.path)}, To: ${String(to.path)}`);
  // current page view title
  document.title = `${process.env.VUE_APP_NAME} / ${to.meta.pageTitle}`;

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // before page access check if page requires authentication
  if (to.meta.middleware == "auth") {
    // verify auth token before each page change
    // store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });
    const AuthStore = useAuthStore();
    // console.log("go to verifyAuth");
    await AuthStore.verifyAuth(AuthStore.getToken());

    // console.log(`isUserAuthenticated 1 ?: ${AuthStore.isUserAuthenticated()}`);
    if (AuthStore.isUserAuthenticated()) {
      next();
    } else {
      next({ name: "sign-in" });
    }
  } else {
    // console.log(`isUserAuthenticated 2 ?: ${AuthStore.isUserAuthenticated()}`);
    // console.log("Puede continuar");
    next();
  }

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;
